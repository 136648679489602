<template>
  <el-dialog
    title="生成采购申请单"
    width="1600px"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfDetail.formData"
      />
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :columns="tableColumn"
        :data="selfDetail.list"
        :show-footer="!!selfDetail.list.length"
        :max-height="this.$util.getViewHeight() - 440"
      >
        <template #default_shen_gou_stock_counts_list="{ row }">
          {{ row.shen_gou_stock_counts_list.join(',') || 0 }}
        </template>
        <template #edit_shen_gou_stock_counts="{ row }">
          <el-input
            v-model="row.shen_gou_stock_counts"
            type="number"
            size="mini"
            clearable
            @blur="countsBlur(row)"
          />
        </template>
        <template #edit_jian_yi_supplier_name="{ row }">
          <el-input v-model="row.jian_yi_supplier_name" size="mini" clearable />
        </template>
        <template #edit_shen_gou_remark_stock="{ row }">
          <el-input v-model="row.shen_gou_remark_stock" size="mini" clearable />
        </template>
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupPush(false)">提交</el-button>
      <el-button size="small" type="primary" @click="popupPush(true)">打印预览</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    billId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        formData: {
          bill_id: '', // 核算单id
          shen_gou_code: '', // 采购申请编号
          bill_code: '', // 销售订单编号
          shen_gou_bill_date: this.$XEUtils.toDateString(this.$XEUtils.now(), 'yyyy-MM-dd'), // 采购申请日期
          shen_gou_remark: '' // 采购申请备注
        },
        list: []
      },
      formConfig: {
        inline: true,
        width: '240px'
      },
      formColumn: [
        {
          type: 'text',
          title: '采购申请编号',
          field: 'shen_gou_code',
          placeholder: '自动生成',
          disabled: true
        },
        {
          type: 'text',
          title: '销售订单编号',
          field: 'bill_code',
          placeholder: '自动生成',
          disabled: true
        },
        {
          type: 'date',
          title: '采购申请日期',
          field: 'shen_gou_bill_date',
          required: true,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'text',
          title: '采购申请备注',
          field: 'shen_gou_remark'
        }
      ],
      tableColumn: [
        {
          fixed: 'left',
          type: 'checkbox',
          width: 40
        },
        {
          title: '序号',
          type: 'seq',
          width: 40
        },
        {
          title: '物料号',
          field: 'wu_liao_code',
          width: 80
        },
        {
          title: '所需物料号',
          field: 'need_wu_liao_code',
          width: 80,
          formatter: ({ cellValue }) => {
            return cellValue.join(',')
          }
        },
        {
          title: '商品类型',
          field: 'type_name',
          width: 60
        },
        {
          title: '品名',
          field: 'craft',
          width: 120
        },
        {
          title: '工序',
          field: 'process_order_name',
          width: 60
        },
        {
          title: '工艺',
          field: 'process_name',
          width: 80
        },
        {
          title: '材质',
          field: 'texture',
          width: 60
        },
        {
          title: '规格',
          field: 'specification',
          width: 120
        },
        {
          title: '单位',
          field: 'unit',
          width: 60
        },
        {
          title: '需求数量',
          field: 'cai_gou_counts',
          width: 80
        },
        {
          title: '已申购数量',
          width: 80,
          slots: { default: 'default_shen_gou_stock_counts_list' }
        },
        {
          title: '本次申购数量',
          field: 'shen_gou_stock_counts',
          width: 100,
          slots: { edit: 'edit_shen_gou_stock_counts' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '申购单价(元)',
          field: 'shen_gou_stock_price',
          width: 80
        },
        {
          title: '申购金额(元)',
          field: 'shen_gou_stock_amount',
          width: 80
        },
        {
          title: '建议供应商',
          field: 'jian_yi_supplier_name',
          slots: { edit: 'edit_jian_yi_supplier_name' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '商品备注',
          field: 'shen_gou_remark_stock',
          slots: { edit: 'edit_shen_gou_remark_stock' },
          editRender: { autofocus: '.el-input__inner' }
        }
      ]
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/shengou/buildShenGouBill',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.selfDetail.formData.bill_id = res.data.formData.bill_id
          this.selfDetail.formData.bill_code = res.data.formData.bill_code

          let list = this.$util.deepCopy(res.data.list)
          list.forEach((item) => {
            // 初始化数量、金额、选中状态
            if (!Number(item.shen_gou_stock_counts)) {
              item.shen_gou_stock_counts = this.$XEUtils.subtract(
                item.cai_gou_counts,
                item.sum_shen_gou_stock_counts
              )
            }
            item.shen_gou_stock_amount = this.$XEUtils.multiply(
              item.shen_gou_stock_price,
              item.shen_gou_stock_counts
            )
          })
          this.selfDetail.list = this.$util.deepCopy(list)
          this.selfDetail.list.forEach((item) => {
            // 需求数量 > 本次申购数量
            if (Number(item.cai_gou_counts) > Number(item.sum_shen_gou_stock_counts)) {
              this.$nextTick(() => {
                this.$refs.refTable.setCheckboxRow([item], true) // 默认选中
              })
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 需求数量-失焦
    countsBlur(row) {
      row.shen_gou_stock_amount = this.$XEUtils.multiply(
        row.shen_gou_stock_price,
        row.shen_gou_stock_counts
      )
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 获取-当前复选行
    getCheckboxRecords() {
      return this.$refs.refTable.getCheckboxRecords()
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupPush(isPushAndPrint) {
      if (!this.selfDetail.formData.shen_gou_bill_date) {
        this.$message.error('请选择采购申请日期')
        return
      }
      let rows = this.getCheckboxRecords()
      if (rows.length) {
        // 判断选中行本次申请数量是否都>0
        let isPass = rows.every((item) => !!Number(item.shen_gou_stock_counts))
        if (!isPass) {
          this.$message.error('选中行的本次申请数量必须>0')
          return
        }
      } else {
        this.itemChoosePrompt()
        return
      }
      let params = {
        formData: {
          ...this.selfDetail.formData
        },
        list: [...rows]
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/shengou/saveOrUpdate',
        data: params
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')

            if (isPushAndPrint) {
              this.popupPrint(res.data.code)
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    // 项-打印
    popupPrint(code) {
      window.open(`/purchase/apply/print-apply?code=${code}`, '_blank')
    }
  }
}
</script>

<style lang="less" scoped></style>
