<template>
  <div class="box-dialog">
    <el-dialog
      title="收款记录"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
      width="1200px"
    >
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :columns="tableColumn"
        :data="listData"
      >
        <template #default_create_time="{ row }">
          {{ row.create_time && row.create_time.slice(0, 10) }}
        </template>
        <template #default_cash_photo_list="{ row }">
          <e-upload-image
            v-if="row.cash_photo_list"
            size="30px"
            disabled
            :list.sync="row.cash_photo_list"
          />
        </template>
        <template #default_cash_status="{ row }">
          <span v-if="row.cash_status === '待审核'" :style="{ color: '#999' }">
            {{ row.cash_status }}
          </span>
          <span v-if="row.cash_status === '已提交'" :style="{ color: '#66b1ff' }">
            {{ row.cash_status }}
          </span>
          <span v-if="row.cash_status === '已审核'" :style="{ color: '#67c23a' }">
            {{ row.cash_status }}
          </span>
        </template>
      </vxe-grid>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    detailId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      tableColumn: [
        {
          title: '序号',
          type: 'seq',
          width: 50
        },
        {
          title: '收款日期',
          field: 'create_time',
          slots: { default: 'default_create_time' }
        },
        {
          title: '收款单号',
          field: 'id'
        },
        {
          title: '收款账户',
          field: 'bank_name',
          width: 160
        },
        {
          title: '收款金额(元)',
          field: 'amount_received'
        },
        {
          title: '实收金额(元)',
          field: 'funds_received',
          width: 100
        },
        {
          title: '收款类型',
          field: 'collection_type'
        },
        {
          title: '客户付款凭证',
          field: 'cash_photo_list',
          width: 200,
          slots: { default: 'default_cash_photo_list' }
        },
        {
          title: '收款备注',
          field: 'remark'
        },
        {
          title: '收款状态',
          field: 'cash_status',
          slots: { default: 'default_cash_status' }
        }
      ],
      listData: []
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let obj = {}
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/reportBill/billCashById',
        params: {
          id: this.detailId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.listData = this.$util.deepCopy(res.data.collection_list)
          this.listData.forEach((item) => {
            if (!item.funds_received) item.funds_received = item.amount_received
            if (item.cash_status === '已提交') {
              this.$nextTick(() => {
                this.$refs.refTable.setCheckboxRow([item], true) // 默认选中
              })
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.popupCancel()
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
